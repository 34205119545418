import React, { useEffect } from "react";
import EmptyImage from "../atoms/EmptyImage";
import { useDropzone } from "react-dropzone";
import forOwn from "lodash-es/forOwn";
import { AddImageProps, CloudinaryProps } from "../../helpers/types";
import deleteIcon from "../../../assets/images/admin/delete-icon.svg";

async function uploadFile(
  file: Record<string, any>,
  cloudinary: CloudinaryProps,
) {
  const imgurl = `https://api.cloudinary.com/v1_1/${cloudinary.name}/image/upload`;

  const data = new FormData();

  data.append("file", file);

  forOwn(cloudinary, (v, k) => {
    data.append(k, v);
  });

  const config = {
    method: "POST",
    body: data,
  };

  const response = await fetch(imgurl, config);

  return await response.json();
}

const ImagePreview = ({
  imageScr,
  handleDelete,
}: {
  imageScr: string;
  handleDelete: () => void;
}) => {
  return (
    <div className="uploaded-image-container">
      <img src={imageScr} alt="preview of upload" />
      <span className="delete-img">
        <button onClick={handleDelete}>
          <img src={deleteIcon} alt="delete-icon" />
        </button>
      </span>
    </div>
  );
};

const AddImage = ({
  label,
  cloudinary,
  values,
  setFieldValue,
  name,
  isMultipleArr,
  transparent,
  triggerClear,
  square,
  multipleImages,
  showFilename,
}: AddImageProps) => {
  const cloudPath = `https://res-5.cloudinary.com/${cloudinary?.name}/image/upload/c_fill`;
  const imageValue = values?.[name as string];
  const getImage = () => {
    if ((name === "pass-promo-img" || name === "post-img") && !!values) {
      return `${cloudPath}/${values.path}`;
    } else if (imageValue && Object.keys(imageValue).length > 0) {
      return `${cloudPath}/${imageValue.path}`;
    } else {
      return null;
    }
  };

  const [image, setImage] = React.useState(getImage());
  const [isLoading, setIsLoading] = React.useState(false);
  const [fileName, setFileName] = React.useState("");

  useEffect(() => {
    const image = values?.[name as string];
    if (!image) setImage(null);
    if (image?.path) setImage(getImage());
  }, [values?.[name as string]]);

  function handleDelete(url?: string) {
    if (isMultipleArr) {
      const newMultipleArr = isMultipleArr;
      for (let i = 0; i < newMultipleArr.length; i++) {
        if (newMultipleArr[i].url === image) {
          newMultipleArr.splice(i, 1);
        }
      }
    } else if (multipleImages) {
      setFieldValue(
        name as string,
        imageValue.filter((image) => image.url !== url),
      );
    } else {
      setFieldValue(name as string, {});
    }
    setImage(null);
    setFileName("");
  }

  React.useEffect(() => {
    if (!!triggerClear) {
      handleDelete();
    }
  }, [triggerClear]);

  const { getInputProps, open } = useDropzone({
    accept: !!transparent
      ? { "image/svg+xml": [], "image/webp": [], "image/png": [] }
      : { "image/jpeg": [], "image/png": [] },
    noClick: true,
    noDrag: true,
    noKeyboard: true,
    multiple: !!multipleImages,
    onDrop: async (acceptedFiles) => {
      try {
        setIsLoading(true);

        const uploadedImages = await Promise.all(
          acceptedFiles.map(async (file) => {
            const result = await uploadFile(file, cloudinary);
            return result;
          }),
        );
        if (isMultipleArr) {
          if (!!image) {
            handleDelete();
          }
          const newIsMultipleArr = isMultipleArr;
          newIsMultipleArr.push(uploadedImages?.[0]);
        } else if (multipleImages) {
          setFieldValue(name as string, [
            ...(imageValue || []),
            ...uploadedImages,
          ]);
        } else {
          setFieldValue(name as string, uploadedImages?.[0]);
        }
        const lastImage = uploadedImages[uploadedImages.length - 1];
        setImage(lastImage.url);
        setFileName(lastImage.original_filename);
      } finally {
        setIsLoading(false);
      }
    },
  });

  return (
    <div className="image-upload-container">
      <div className="my-2 radio-label-top">{label}</div>

      <div className="image-select-mass-gifting">
        <div className="image-select-mass-gifting-top">
          {image && !multipleImages ? (
            <ImagePreview imageScr={image} handleDelete={handleDelete} />
          ) : (
            <EmptyImage />
          )}

          <figure className="button-column">
            <span className={`${fileName ? "" : "image-label"}`}>
              {(transparent || showFilename) && fileName ? fileName : null}
              {square
                ? "Image"
                : !(transparent && fileName) && label
                  ? "Select File"
                  : "Attach image"}
            </span>
            <span className="small-gray-text">
              {!!transparent
                ? "SVG, WEBP or transparent PNG under 1 MB"
                : square
                  ? "Image should be square, 512x512 pixels minimum."
                  : "JPEG, PNG, or JPG under 1 MB"}
            </span>
            <input {...getInputProps()} />
            <button
              className="custom-file-input"
              onClick={open}
              disabled={isLoading}
              type="button"
            ></button>
          </figure>
        </div>
        {multipleImages && imageValue?.length ? (
          <div className="images-preview">
            {imageValue.map((image) => (
              <ImagePreview
                key={image.url}
                imageScr={image.url}
                handleDelete={handleDelete.bind(null, image.url)}
              />
            ))}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default AddImage;
