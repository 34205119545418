import React, { ReactNode } from "react";
import PartnerPortalTooltip from "../atoms/PartnerPortalTooltip";
import { useFormikContext, FormikTouched } from "formik";

interface PartnerPortalInputWrapperProps {
  label?: string;
  tooltip?: string;
  optional?: boolean;
  hideLabel?: boolean;
  name: string;
  children: ReactNode;
  index?: number;
  disabled?: boolean;
}

const PartnerPortalInputWrapper = ({
  label,
  tooltip,
  optional,
  hideLabel,
  name,
  children,
  index,
  disabled,
}: PartnerPortalInputWrapperProps) => {
  const { errors, touched, values } = useFormikContext<{
    errors: { [key: string]: any };
    touched: FormikTouched<any> | FormikTouched<any>[];
    res_data: { [key: string]: any };
    contact: any;
  }>();

  return (
    <div className={`pp-input__container ${disabled ? "input-disabled" : ""}`}>
      {hideLabel ? null : (
        <div className="pp-input__label-container">
          <label
            className={`pp-input__label ${label === "need-help-modal" ? "need-help-email-label" : ""}`}
          >
            {label}
          </label>
          {tooltip ? <PartnerPortalTooltip tooltip={tooltip} /> : null}
          {optional ? (
            <span className="pp-input__optional">Optional</span>
          ) : null}
        </div>
      )}
      {children}
      {typeof index === "number" &&
        errors?.res_data?.[index]?.contact &&
        touched?.res_data?.[index]?.contact && (
          <span className="pp-input__error-msg">
            {Object.values(errors?.res_data?.[index]?.contact)}
          </span>
        )}
      {errors[name] &&
        touched[name] &&
        typeof errors[name] === "object" &&
        typeof index !== "number" && (
          <span className="pp-input__error-msg">
            {Object.values(errors[name]).length > 1
              ? "Please select a valid image before continuing."
              : ""}
          </span>
        )}
      {errors[name] && touched[name] && typeof errors[name] === "string" && (
        <span className={`pp-input__error-msg pp-input__error-${name}`}>
          {String(errors[name])}
        </span>
      )}
      {label === "Primary Phone Number" &&
        values[name].replace(/\D/g, "").length < 2 &&
        !errors[name] &&
        !touched[name] && (
          <span className={`pp-input__error-msg pp-input__dummy-error-phone`}>
            This field is required if takeout is offered.
          </span>
        )}
    </div>
  );
};
export default PartnerPortalInputWrapper;
