/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import React from "react";
import { useFormikContext } from "formik";
import { calculateFontColorFor } from "../../lib/calculateFontColor";

const SubmitButton = ({ verb, isBslCheckout, accentColor, curPage }) => {
  const { values, isSubmitting } = useFormikContext();
  const btnIsActive = () => {
    if (isBslCheckout) return true;
    return (
      (values.email.length > 0 && curPage === 3) ||
      (values.sixDigitCode.length > 0 && curPage === 2) ||
      (values.password.length > 4 && curPage === 5) ||
      (values.password.length > 4 &&
        values.confirmPassword.length > 4 &&
        curPage === 4) ||
      (values.phone.length > 3 && curPage === 0) ||
      (values.phone.length > 3 && curPage === 1)
    );
  };
  const activeBtnStates = () => {
    let active;
    if (btnIsActive()) {
      active = "active-btn";
    }
    return active;
  };
  return (
    <button
      disabled={!btnIsActive()}
      className={`login-ca__button ${activeBtnStates()} login-ca__continue-button ${
        isSubmitting ? "loading-btn" : "action"
      } ${isBslCheckout ? "login-ca__bsl" : ""}`}
      type="submit"
      css={css`
        background-color: ${(values?.phone.length > 0 && curPage === 0) ||
        (values?.sixDigitCode.length > 0 && curPage === 2)
          ? accentColor
          : "rgba(116, 120, 108, 0.15)"};
      `}
    >
      {verb}
    </button>
  );
};

export default SubmitButton;
