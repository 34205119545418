import React from "react";
import noteIcon from "../../../assets/images/icons/sticky-note.svg";
import emailedIcon from "../../../assets/images/icons/emailed_solid.svg";
import StatusArrowIcon from "../../../assets/images/icons/modal-status-arrow.svg";
import inkindLogo from "../../../assets/images/icons/inkind-white-logo.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser as faUserSolid } from "@fortawesome/pro-solid-svg-icons";
import { convertObjectKeysToCamelCase } from "../../helpers/convertObjectKeysToCamelCase";

const snakeToTitleCase = (snakeStr) => {
  if (!snakeStr) return "";

  return snakeStr
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

const getInitials = (name) => {
  if (!name) return "";
  const [first = "", last = ""] = name.trim().split(" ");
  return `${first[0] || ""}${last[0] || ""}`.toUpperCase();
};

const getAvatarDetails = (
  userName,
  userEmail,
  isModalActivity,
  isYou,
  userType,
) => {
  // If not a modal activity, return early with a default set
  if (!isModalActivity) {
    return {
      bgcolor: "#e7b84b",
      displayName: userName || "",
      icon: getInitials(userName),
    };
  }

  // System user
  if (userType === "system") {
    return {
      bgcolor: "#e7b84b",
      displayName: "System",
      icon: <img src={inkindLogo} className="inkind-logo" alt="inkind logo" />,
    };
  }

  // Anonymous user: missing email
  if (!userEmail) {
    return {
      bgcolor: "#A6A6A6",
      displayName: "Anonymous",
      icon: <FontAwesomeIcon icon={faUserSolid} style={{ fontSize: "16px" }} />,
    };
  }

  // Valid user with email
  return {
    bgcolor: userEmail.includes("@inkind.com") ? "#03C095" : "#A6A6A6",
    displayName: isYou ? "You" : userName || "",
    icon: getInitials(userName),
  };
};

const makeAfterCommaBold = (message) => {
  const [beforeComma, afterComma] = message.split(/,(.+)/);
  if (!afterComma) {
    return <>{message}</>;
  } else {
    return (
      <>
        {beforeComma}, <span className="bold">{afterComma.trim()}</span>
      </>
    );
  }
};

const ActivityAttachment = ({ attachment }) => {
  const size = attachment.filesize.toFixed(1);
  return (
    <div className="activity-attachment">
      <img
        className="activity-attachment-image"
        src={attachment.url}
        onClick={() => {
          window.open(attachment.url, "_blank");
        }}
      />
      <div>
        {attachment.filename}
        <span className="activity-date">
          &nbsp;{" • "}&nbsp;{size === "0.0" ? "0.1" : size} MB
        </span>
      </div>
    </div>
  );
};

const ActivityRow = ({
  activity,
  index,
  getDateTime,
  getOrdinal,
  brandOwners,
  isModalActivity = false,
}) => {
  const {
    comment,
    createdAt,
    currentStatus,
    prevStatus,
    userName,
    userEmail,
    images,
    message,
    previewSent,
    activityType,
    isInternal,
    userType,
    isYou,
  } = convertObjectKeysToCamelCase(activity || {});
  const avatarDetails = getAvatarDetails(
    userName,
    userEmail,
    isModalActivity,
    isYou,
    userType,
  );
  const showStatusChange =
    isModalActivity &&
    currentStatus &&
    prevStatus &&
    currentStatus !== prevStatus;

  return (
    <li className="activity-row">
      <div
        className="activity-image"
        style={{
          backgroundColor: avatarDetails.bgcolor,
        }}
      >
        {avatarDetails.icon}
      </div>
      <div className="activity-action">
        <div>
          <span className="activity-sender">
            {avatarDetails.displayName}
            {isInternal === true && (
              <>
                <span className="activity-date">&nbsp;{" • "}&nbsp;</span>
                <span className="activity-internal">Note to self</span>
              </>
            )}
          </span>
          <span className="activity-date">
            &nbsp;{" • "}&nbsp;
            {getDateTime(createdAt)}
          </span>
        </div>
        {comment || message ? (
          <div className="activity-note">
            {userType === "system"
              ? makeAfterCommaBold(comment)
              : comment || message}
          </div>
        ) : null}
        {showStatusChange ? (
          <div className="activity-modal-status-container">
            <div className={`activity-modal-status-icon ${prevStatus}`}>
              <span>{snakeToTitleCase(prevStatus)}</span>
            </div>
            <img src={StatusArrowIcon} alt="status arrow" />
            <div className={`activity-modal-status-icon ${currentStatus}`}>
              <span>{snakeToTitleCase(currentStatus)}</span>
            </div>
          </div>
        ) : null}
        {previewSent === true && (
          <div className="activity-emailed-button">
            <img src={emailedIcon} />
            <span>
              Preview emailed to{" "}
              {isInternal === false ? brandOwners?.join(", ") : userName}
            </span>
          </div>
        )}
        {activityType === "partner_approval" && (
          <div className="activity-approved-button">
            <span>Approved</span>
          </div>
        )}

        <div
          className="activity-attachments-container"
          style={{ display: images?.length > 0 ? "flex" : "none" }}
        >
          {images?.map((attachment, index) => {
            return <ActivityAttachment attachment={attachment} key={index} />;
          })}
        </div>
      </div>
    </li>
  );
};

export default ActivityRow;
