import React from "react";
import PartnerPortalInputWrapper from "../partner-portal/molecules/PartnerPortalInputWrapper.tsx";
import ConditionalWrapper from "../../ui/atoms/ConditionalWrapper/ConditionalWrapper.jsx";
import InputLayout from "../../ui/molecules/InputLayout.tsx";
import PhoneInput from "react-phone-input-2";
import { Field, useFormikContext } from "formik";
import "detect-autofill";

const PhoneEntry = ({
  showTerms,
  country,
  prevPhoneEntry,
  prevValue,
  setPrevValue,
  isPartnerPortal,
  isBslCheckout,
}) => {
  const {
    values,
    handleBlur,
    handleChange,
    setFieldValue,
    submitForm,
    validateField,
    errors,
  } = useFormikContext();

  const [labelDisplay, setLabelDisplay] = React.useState("");
  const [didAutoComplete, setDidAutoComplete] = React.useState(false);

  const countryVals = {
    NZ: {
      noCcLength: 8,
      cCLength: 10,
      doubleCc: "6464",
      countryCode: "64",
      minEditable: 3,
    },
    US: {
      noCcLength: 10,
      cCLength: 11,
      doubleCc: "11",
      countryCode: "1",
      minEditable: 2,
    },
    CA: {
      noCcLength: 10,
      cCLength: 11,
      doubleCc: "11",
      countryCode: "1",
      minEditable: 2,
    },
    AU: {
      noCcLength: 10,
      cCLength: 12,
      doubleCc: "6161",
      countryCode: "61",
      minEditable: 3,
    },
  };

  const handleNumChange = (ccFromChange) => {
    //for some reason getting the value directly rather than using React state handles Lastpass/autofill better
    const valNums = String(
      document.getElementById("phone-input").value?.replace(/\D/g, ""),
    );
    let curValNums = valNums;
    const curCountry =
      countryVals[ccFromChange ?? values.countryCode] ?? countryVals.US;
    //five cases below: iPhone autofill w/o CC, wrong/no country code (diff version for each apge), duplicate country code (user error), and invalid paste prevention
    //if it's a valid input that lacks the country code (eg, iphone autofill), add country code and submit
    if (
      valNums.length === curCountry.noCcLength &&
      valNums?.length - prevValue.length > 1 &&
      valNums.indexOf(curCountry.countryCode) !== 0
    ) {
      curValNums = curCountry.countryCode + valNums;
      setFieldValue("phone", curValNums).then(() => {
        setDidAutoComplete(false);
        submitForm();
      });
      //if number starts with wrong country code (eg, Lastpass or catastropic failure) and it's the first step, change to country code only
    } else if (valNums.indexOf(curCountry.countryCode) !== 0 && !showTerms) {
      curValNums = curCountry.countryCode;
      setFieldValue("phone", curValNums);
      //if number starts with wrong country code (eg, Lastpass or catastropic failure) and second step, change to original country code from Step 1
    } else if (valNums.indexOf(curCountry.countryCode) !== 0) {
      setFieldValue("phone", curValNums);
      //if user has duplicated country code, (re)insert country code
    } else if (valNums === curCountry.doubleCc) {
      curValNums = curCountry.countryCode;
      setFieldValue("phone", curValNums);
      //if sudden increase in number of digits that doesn't seem to match a valid phone number (eg, pasting a couple digits,
      //Lastpass fill problem that includes numbers), ignore and revert to previous value
    } else if (
      valNums.length > curCountry.countryCode.length + 1 &&
      valNums?.length - prevValue.length > 1 &&
      valNums?.length !== curCountry.cCLength &&
      !didAutoComplete
    ) {
      curValNums = prevValue;
      setFieldValue("phone", curValNums);
    }
    //save previous value for final step above, allowing reversion if things get weird
    setPrevValue(curValNums);
  };

  React.useEffect(() => {
    if (values.phone.length > 0) {
      setLabelDisplay("no-placeholder");
    }
    document
      .querySelector(".phone-input input")
      ?.addEventListener("keyup", function (e) {
        if (e.key?.indexOf("Arrow") === 0) {
          return;
        }
        const valNums = e.target.value.replace(/\D/g, "");
        if (
          (!showTerms || values.agreeToComm) &&
          valNums.length > 10 &&
          valNums[0] === "1"
        ) {
          submitForm();
        }
      });
    document.addEventListener("onautocomplete", function (e) {
      setDidAutoComplete(e.target.hasAttribute("autocompleted"));
    });
  }, []);

  const handleLabelPosition = (val, data, e) => {
    const countryCode = data.countryCode.toUpperCase();
    setFieldValue("countryCode", countryCode);
    if (labelDisplay !== "" && val === data.dialCode) {
      setLabelDisplay("");
    } else if (labelDisplay === "") {
      setLabelDisplay("no-placeholder");
    }
    setFieldValue("phone", val).then(() => {
      //in cases where number is incorrectly formatted, this function will fix
      //otherwise it does nothing and no change is made
      handleNumChange(countryCode);
    });
  };

  return (
    <>
      <ConditionalWrapper
        condition={isPartnerPortal}
        wrapper1={(children) => (
          <InputLayout
            label={isBslCheckout ? "" : "Enter your phone number"}
            error={errors.phone}
            labelOverride={`phone-num-floating-label ${labelDisplay}`}
          >
            {children}
          </InputLayout>
        )}
        wrapper2={(children) => (
          <PartnerPortalInputWrapper
            label="Enter your phone number"
            name={"phone"}
          >
            {children}
          </PartnerPortalInputWrapper>
        )}
      >
        <PhoneInput
          value={values.phone}
          inputProps={{
            name: "phone",
            type: "tel",
            autoComplete: "tel",
            id: "phone-input",
            "data-lpignore": "true",
          }}
          className="phone-input checkout-base-font"
          disableDropdown={!!country}
          country={country ? country?.toLowerCase() : "us"}
          onlyCountries={
            country ? [country.toLowerCase()] : ["au", "ca", "nz", "us"]
          }
          inputStyle={{
            height: `${isBslCheckout ? "64px" : "47.85px"}`,
            width: "100%",
            border: "none",
            borderRadius: "7.5px",
          }}
          buttonStyle={{
            backgroundColor: "transparent",
            border: "none",
            outline: "none",
          }}
          aria-label="Enter your phone number"
          onChange={(val, data, e) => {
            handleLabelPosition(val, data, e);
          }}
          onBlur={(e, data) => {
            handleLabelPosition(e.target.value, data, e);
          }}
          onKeyDown={(event) => {
            const ccToCheck =
              values?.countryCode?.length > 1 ? values.countryCode : "US";
            //here we handle stuff that we want to occur PRIOR to change eg prevent delete countrycode
            //unfortunately onKeyDown doesn't handle autofill/lastpass so we have to do this after change
            const minEditable = countryVals[ccToCheck].minEditable;
            const ccToUse = countryVals[ccToCheck].countryCode;
            if (
              event.target.selectionStart < minEditable &&
              event.target.value.length > 1 &&
              isFinite(event.key)
            ) {
              event.preventDefault();
              return;
            }
            if (event.key === "Enter") {
              event.preventDefault();
              submitForm();
            }
            if (
              (event.key === "Backspace" || event.key === "Delete") &&
              values.phone === ccToUse
            ) {
              event.preventDefault();
            }
          }}
        />
      </ConditionalWrapper>
      <input
        type="email"
        name="dummy-email"
        aria-label="email"
        placeholder=" "
        style={{
          height: "0px",
          width: "0px",
          position: "absolute",
          left: "-9999px",
          top: "-9999px",
        }}
        className="floating-label-input"
      />
      {showTerms ? (
        <InputLayout overrideClass={"sms"} error={errors.agreeToComm}>
          <div
            className={`login-ca__check-box ${errors.agreeToComm ? "login-ca__check-box-error" : ""}`}
            style={{ alignItems: "start" }}
          >
            <Field
              id="agreeToComm"
              type="checkbox"
              name="agreeToComm"
              onChange={(event) => {
                handleChange(event);
                setTimeout(() => validateField("agreeToComm"), 0);
              }}
              checked={values.agreeToComm}
            />
            <label htmlFor="agreeToComm">
              I agree to receive text messages from inKind, including
              verification and marketing messages at the phone number provided.
              Reply STOP to cancel. Message and data rates may apply.
            </label>
          </div>
        </InputLayout>
      ) : null}
    </>
  );
};

export default PhoneEntry;
