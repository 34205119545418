function toCamelCase(str: string): string {
  return str.replace(/([-_][a-z])/gi, ($1) => {
    return $1.toUpperCase().replace("_", "");
  });
}

export function convertObjectKeysToCamelCase<T>(obj: T) {
  if (typeof obj !== "object" || obj === null) {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map((item) => convertObjectKeysToCamelCase(item));
  }

  const result: any = {};
  Object.keys(obj).forEach((key) => {
    const val = (obj as any)[key];
    const newKey = toCamelCase(key);
    result[newKey] = convertObjectKeysToCamelCase(val);
  });

  return result;
}
