import { useFormikContext } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { currency } from "../../lib/helpers";
import { FormValues } from "./type";
import FormikTextInput from "../../ui/atoms/formik-text-input";

interface TipOption {
  value: number | null;
  label: string;
}

export const generateTipOptions = (
  values: (number | string)[],
  prefix: string | null = null,
  suffix: string | null = null,
): TipOption[] => {
  return values.map((value) => {
    let label: string;

    if (value === 0) {
      label = "None";
    } else if (typeof value === "number") {
      label = `${prefix || ""}${value}${suffix || ""}`;
    } else {
      label = `${value}`;
    }

    return {
      value: typeof value === "number" ? value : 0,
      label,
    };
  });
};

const defaultTipOptions = generateTipOptions(
  [18, 20, 22, 25, "Custom"],
  null,
  "%",
);
const under5TipOptions = generateTipOptions([0.75, 1, 2, 3, "Custom"], "$");
const under10TipOptions = generateTipOptions([1, 2, 3, 4, "Custom"], "$");
const autoGratTipOptions = generateTipOptions(
  [0, 5, 10, 15, "Custom"],
  null,
  "%",
);

export enum TipType {
  Percentage,
  Fixed,
}
type TipOptionsProps = {
  tipCalculationBase: number;
  tipOptions: (number | undefined)[];
  hasAutomaticGratuity: boolean;
};

export const getTipOptions = (
  tipCalculationBase: number,
  tipOptions: (number | undefined)[],
  hasAutomaticGratuity: boolean,
) => {
  if (hasAutomaticGratuity) return autoGratTipOptions;
  if (tipCalculationBase < 5) return under5TipOptions;
  if (tipCalculationBase < 10) return under10TipOptions;
  const isTipOptionsValid = tipOptions.every(
    (tip) => tip !== null && tip !== undefined,
  );
  return isTipOptionsValid
    ? [
        ...tipOptions.map((tip) => ({ value: tip, label: `${tip}%` })),
        {
          value: 0,
          label: "Custom",
        },
      ]
    : defaultTipOptions;
};

export const calculateTip = (
  billAmount: number,
  selectedOption: number,
  tipType: TipType,
) => {
  if (tipType === TipType.Percentage) {
    return currency((billAmount * selectedOption) / 100);
  } else return currency(selectedOption);
};

export const formatCurrency = (inputValue: string): string => {
  if (inputValue.trim() === "") return "";

  const numberValue = parseInt(inputValue, 10);

  const dollars = Math.floor(numberValue / 100);
  const cents = numberValue % 100;

  return `$${dollars}.${cents.toString().padStart(2, "0")}`;
};

export const TipOptions: React.FC<TipOptionsProps> = ({
  tipCalculationBase = 0,
  tipOptions,
  hasAutomaticGratuity,
}) => {
  const { values, setFieldValue } = useFormikContext<FormValues>();
  const [selectedLabel, setSelectedLabel] = useState("");
  const tipInputRef = useRef<HTMLInputElement>(null);
  const tipType =
    hasAutomaticGratuity || tipCalculationBase >= 10
      ? TipType.Percentage
      : TipType.Fixed;

  const displayOptions =
    getTipOptions(tipCalculationBase, tipOptions, hasAutomaticGratuity) || [];
  const tipAmount = values.tip_amount;

  const updateTipAmount = (newTip: number | null, label?: string) => {
    if (label === "Custom") tipInputRef?.current?.focus();
    const tip = calculateTip(tipCalculationBase, newTip || 0, tipType);
    setFieldValue("tip_amount", tip);
  };

  useEffect(() => {
    const preselectedIndex = hasAutomaticGratuity
      ? 1
      : tipType === TipType.Percentage
        ? 2
        : 1;
    updateTipAmount(displayOptions?.[preselectedIndex]?.value || 0);
    setSelectedLabel(displayOptions?.[preselectedIndex]?.label);
  }, [tipType, tipCalculationBase]);

  const getSelectedClassname = (option: TipOption) => {
    const isCustomSelected =
      selectedLabel === "Custom" && selectedLabel === option.label;
    const isStandardTipSelected =
      tipAmount ===
        calculateTip(tipCalculationBase, option.value || 0, tipType) &&
      selectedLabel !== "Custom" &&
      option.label !== "Custom";
    return isCustomSelected || isStandardTipSelected ? "selected" : "";
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const numericValue = value.replace(/\D/g, "");

    if (numericValue === "") {
      setFieldValue("tip_amount", "$0.00");
      return;
    }

    const formattedValue = formatCurrency(numericValue);
    setFieldValue("tip_amount", formattedValue);
  };

  return (
    <>
      <div className="tip">Tip</div>
      <FormikTextInput
        label="Tip Amount"
        name="tip_amount"
        className="tip-input"
        inputRef={tipInputRef}
        onChange={handleChange}
      />
      <div className="tip-options">
        {displayOptions.map((option) => (
          <button
            className={`option ${getSelectedClassname(option)}`}
            key={option.label}
            onClick={() => {
              updateTipAmount(option.value, option.label);
              setSelectedLabel(option.label);
            }}
            type="button"
          >
            {option.label}
          </button>
        ))}
      </div>
    </>
  );
};
