import React from "react";

const BrandInfo = ({
  location,
  brandToUse,
  i,
  counter,
  cloudinaryPath,
  userLocErr,
  handleBrandMouseIn,
  handleBrandMouseOut,
  handleBrandInfoClick,
  tags,
  getFeaturedTags,
}) => {
  const [tagsText, setTagsText] = React.useState([]);

  const locMarker = location.marker;
  let hero =
    brandToUse.branding.hero_image.value.indexOf(".png") !== -1
      ? brandToUse.branding.hero_image.value.replace(".png", ".jpg")
      : brandToUse.branding.hero_image.value;

  React.useEffect(() => {
    if (tags) {
      setTagsText(getFeaturedTags(brandToUse));
    }
  }, [tags]);

  return (
    <div
      className={`loc-container loc-container-${counter} ${
        counter > 15 ? "mobile-hide-info" : ""
      }`}
      onClick={() => {
        handleBrandInfoClick(location, location.marker, brandToUse.brand_id);
      }}
      onMouseEnter={() => {
        handleBrandMouseIn(location, location.marker);
      }}
      onMouseLeave={() => {
        handleBrandMouseOut(location, location.marker);
      }}
    >
      <div
        className="loc-tile"
        style={{
          backgroundImage: `url(${cloudinaryPath}/b_black,o_67,c_fit,w_240/${hero})`,
        }}
      >
        {brandToUse?.branding?.logo?.dark_mode && (
          <img
            className="loc-logo"
            src={`${cloudinaryPath}/w_104/${brandToUse.branding.logo.dark_mode}`}
          />
        )}
      </div>
      <div className="text-container">
        <div className="loc-header">{brandToUse.name}</div>
        <div className="loc-subhead info-tags">
          {tagsText.map((tag, index) => {
            return <span key={index}>{tag}</span>;
          })}
        </div>
        <div
          className="loc-distance"
          style={{ display: !!userLocErr ? "none" : "block" }}
        >
          {location.staticDistance + " Miles Away"}
        </div>
      </div>
      <div className="select-button"></div>
    </div>
  );
};

export default BrandInfo;
