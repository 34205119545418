export const getOrdinal = (day) => {
  if (day > 3 && day < 21) return "th";
  switch (day % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
};

export const getDateTime = (dateTime, noDate, showYear) => {
  let monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  let dayNames = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  let date = new Date(dateTime);
  let month = monthNames[date.getMonth()];
  let day = date.getDate();
  let weekDay = dayNames[date.getDay()];
  let ordinal = getOrdinal(day);
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let year = date.getFullYear();
  let ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  const strTime = !!noDate
    ? weekDay + ", " + month + " " + day + ordinal
    : month +
      " " +
      day +
      ordinal +
      ", " +
      (showYear ? year + " at " : "") +
      hours +
      ":" +
      minutes +
      ampm;
  return strTime;
};

export const getRelativeTime = (createdAt) => {
  const now = new Date();
  const createdTime = new Date(createdAt);
  const differenceInSeconds = Math.floor((+now - +createdTime) / 1000);

  if (differenceInSeconds < 60) {
    return "Just now";
  }

  return getDateTime(createdAt, "", true);
};
